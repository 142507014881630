import { Link } from 'react-router-dom';
import user_1_Image from '../Assets/images/Teams/user-1-img.jpg';
import user_2_Image from '../Assets/images/Teams/user-2-img.jpg';
import user_3_Image from '../Assets/images/Teams/user-3-img.jpg';
export default function Team(){

    return(
        <>
            <div class="guide-area guide-style-one pt-110">
        <div class="container reveal fade-bottom">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-head-gamma">
                <h2>Our Team</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="guide-card-gamma">
                <div class="guide-image">
                  {/* <img src={user_1_Image} alt='user' style={{height: '25rem'}}/> */}
                  <ul class="guide-social-links">
                    <li>
                      <Link to="tel:9936387710">
                      <i class="fa fa-phone-square" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://wa.me/9936387710" target="_blank">
                        <i class="bx bxl-whatsapp"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="mailto:ticket@jamaltourandtravels.com" target="_blank">
                        <i class="fa fa-envelope"></i>
                      </Link>
                    </li>
                   
                  </ul>
                 
                </div>
                <div class="guide-content">
                  <h6 class="guide-designation">Contact Number : &nbsp; 
                  <Link to="tel:9936387710" target="_blank" style={{color:'#666',textTransform:'lowercase'}}>
                   +919936387710
                      </Link>
                  </h6>
                  <h6 class="guide-designation">Mail Address : &nbsp;   <Link to="mailto:ticket@jamaltourandtravels.com" target="_blank" style={{color:'#666',textTransform:'lowercase'}}>
                  ticket@jamaltourandtravels.com
                      </Link></h6>
                  <h4 class="guide-name mt-2">Zafar Khan</h4>
                  <h6 class="guide-designation">Reservation</h6>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="guide-card-gamma">
                <div class="guide-image">
                  {/* <img src={user_2_Image} alt='' style={{height: '25rem'}}/> */}
                  <ul class="guide-social-links">
                  <li>
                      <Link to="tel:8874511511">
                      <i class="fa fa-phone-square" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://wa.me/8874511511" target="_blank">
                        <i class="bx bxl-whatsapp"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="mailto:emigration@jamaltourandtravels.com" target="_blank">
                        <i class="fa fa-envelope"></i>
                      </Link>
                    </li>
                  </ul>
                  
                </div>
                <div class="guide-content">
                <h6 class="guide-designation">Contact Number : &nbsp; 
                  <Link to="tel:+918874511511" target="_blank" style={{color:'#666',textTransform:'lowercase'}}>
                   +918874511511
                      </Link>
                  </h6>
                  <h6 class="guide-designation">Mail Address : &nbsp;   <Link to="mailto:emigration@jamaltourandtravels.com" target="_blank" style={{color:'#666',textTransform:'lowercase'}}>
                  emigration@jamaltourandtravels.com
                      </Link></h6>
                  <h4 class="guide-name mt-2">Abdul Mateen</h4>
                  <h6 class="guide-designation">Stamping Emigration</h6>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="guide-card-gamma">
                <div class="guide-image">
                  {/* <img src={user_3_Image} alt='' style={{height: '25rem'}}/> */}
                  <ul class="guide-social-links">
                  <li>
                      <Link to="tel:8948511511">
                      <i class="fa fa-phone-square" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://wa.me/8948511511" target="_blank">
                        <i class="bx bxl-whatsapp"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="mailto:accounts@jamaltourandtravels.com" target="_blank">
                        <i class="fa fa-envelope"></i>
                      </Link>
                    </li>
                  </ul>
                 
                </div>
                <div class="guide-content">
                <h6 class="guide-designation">Contact Number : &nbsp; 
                  <Link to="tel:+918948511511" target="_blank" style={{color:'#666',textTransform:'lowercase'}}>
                   +918948511511
                      </Link>
                  </h6>
                  <h6 class="guide-designation">Mail Address : &nbsp;   <Link to="mailto:accounts@jamaltourandtravels.com" target="_blank" style={{color:'#666',textTransform:'lowercase'}}>
                  accounts@jamaltourandtravels.com
                      </Link></h6>
                  <h4 class="guide-name mt-2">Dawood Sheikh</h4>
                  <h6 class="guide-designation">Accounts</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    )
} 