import { Link, Outlet } from "react-router-dom";
import JTTLogo from "../Assets/images/JIT-Main-Logo.png";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { useEffect } from "react";

export default function Header() {
  // useEffect(()=>{
  //     $(".hamburger").on("click", function (event) {
  //         $(this).toggleClass("h-active");
  //         $(".main-nav").toggleClass("slidenav");
  //       });
  //       $(".header-home .main-nav ul li  a").on("click", function (event) {
  //         $(".hamburger").removeClass("h-active");
  //         $(".main-nav").removeClass("slidenav");
  //       });
  //       $(".main-nav .fl").on("click", function (event) {
  //         var $fl = $(this);
  //         $(this).parent().siblings().find(".sub-menu").slideUp();
  //         $(this).parent().siblings().find(".fl").addClass("flaticon-plus").text("+");
  //         if ($fl.hasClass("flaticon-plus")) {
  //           $fl.removeClass("flaticon-plus").addClass("flaticon-minus").text("-");
  //         } else {
  //           $fl.removeClass("flaticon-minus").addClass("flaticon-plus").text("+");
  //         }
  //         $fl.next(".sub-menu").slideToggle();
  //       });
  //       $(".search-btn").on("click", function () {
  //         $(".mobile-search").addClass("slide");
  //       });
  //       $(".search-cross-btn").on("click", function () {
  //         $(".mobile-search").removeClass("slide");
  //       });
  // },[])
  return (
    <>
      {/* <header>
        <div className="header-area header-style-three position-absolute w-100">
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="col-xxl-2 col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 align-items-center d-xl-flex d-lg-block">
                        <div className="nav-logo d-flex justify-content-between align-items-center">
                            <a href="index.html"><img src={JTTLogo} alt="logo" style={{height:'50px'}}/></a>
                            <div className="mobile-menu d-flex ">
                                <div className="d-flex align-items-center">
                                    <div className="nav-right-icons d-xl-none d-flex align-items-center ">
                                        <div className="user-dropdown">
                                            <i className="bx bx-user-circle"></i>
                                            <ul className="user-drop-list">
                                                <li><a href="#">My Account</a></li>
                                                <li><a href="#">Login</a></li>
                                                <li><a href="#">Registration</a></li>
                                                <li><a href="#">Setting</a></li>
                                            </ul>
                                        </div>
                                        <div className="category-toggle"><i className="bx bx-category"></i></div>
                                    </div>
                                    <a href="javascript:void(0)" className="hamburger d-block d-xl-none">
                                        <span className="h-top"></span>
                                        <span className="h-middle"></span>
                                        <span className="h-bottom"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-7 col-xl-8 col-lg-9 col-md-8 col-sm-6 col-xs-6">
                        <nav className="main-nav">
                            <div className="inner-logo d-xl-none text-center">
                                <a href="#"><img src="assets/images/logo.png" alt /></a>
                            </div>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li className="has-child-menu"> <span style={{color:'white'}}>Packages</span>
                                    <ul className="sub-menu mt-2">
                                    <li><Link to="/InternationalPackages">Umrah Package</Link></li>
                                    <li><Link to="/InternationalPackages">Internation Package</Link></li>
                                    <li><Link to="/DomesticPackages">Domestic Package</Link></li>
                                    </ul>
                                </li>

                                <li><Link to="/About-Us">About Us</Link></li>
                                <li ><Link to="/Contact-Us">Contact Us</Link></li>
                            </ul>
                            
                        </nav>
                    </div>
                    <div className="col-xxl-3 col-xl-2 col-lg-1">
                        <div className="nav-right d-xl-flex d-none">
                            <div className="nav-right-hotline d-xxl-flex d-none">
                                <div className="hotline-info">
                                    <span>Contact Number</span>
                                    <h6><a href="tel:9936387710">+91 9936387710</a></h6>
                                </div>
                            </div>
                            <div className="nav-right-icons gap-3">
                                <div className="header-search search-btn">
                                <a href="tel:9936387710"> <i className="bx bx-phone"></i></a>
                                </div>
                                <div className="category-toggle"><a href="https://wa.me/9936387710" target='blank'><i className="bx bxl-whatsapp"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header> */}
    <>
   
  
  {/* <div className="row stickyFormm">
    <div className="col-lg-8"></div>
    <div className="col-lg-4">
    <div className="row">
    <div class="form-group col-lg-5 col-md-4 col-sm-4 mb-2 ">
    <input type="text" class="form-control" id="inputPassword2" placeholder="Username" style={{height:'35px',marginTop: '7px'}} />
  </div>
  <div class="form-group col-lg-4 col-md-4 col-sm-4 mb-2">
    <input type="password" class="form-control" id="inputPassword2" placeholder="Password" style={{height:'35px',marginTop: '7px'}} />
  </div>
  <div class="col-lg-3 col-md-4 col-sm-4 mb-2">
  <button type="submit" class="btn btn-success mb-2" style={{height: '35px',marginTop: '7px'}}><i class="fa fa-sign-in" aria-hidden="true"></i>&nbsp;Login</button>
  </div>
    </div>
    </div>
  
  </div> */}
  <div className="stickyAboveHeader" style={{display:'flex',background:'linear-gradient(179.62deg, rgb(214 207 201) -0.03%, #0097b6 100.08%',justifyContent:'flex-end'}}>
    <div style={{display:'flex', flexDirection:'row',gap:"20px",margin:'10px'}} >
       <input style={{height:'fit-content',padding:"5px 10px"}} type="text" placeholder="Username"/>
       <input style={{height:'fit-content',padding:"5px 10px"}} type="password" placeholder="Password"/>
       <button style={{borderRadius:"7px", background:'linear-gradient(179deg, #00d4ff -0.03%, #ffe6ec 100.08%)',color:'#242323eb',border:'none',fontSize:'15px',fontWeight:'600',padding:'0px 14px',display:'flex',alignItems:'center',justifyContent:'center',gap:"10px"}}><i class="fa fa-sign-in" aria-hidden="true"></i>Login</button>
    </div>
  </div>
   
      <header className="header">
        <Link to="/" className="logo">
          
          <img src={JTTLogo} alt="logo" style={{ height: "50px" }} />

        </Link>
       <Link to="/" className="logo brandName">
       <span className="responsiveHeading">Jamal Tour And <span className="lastBrandName">Travels</span></span>
       </Link>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label
          className="menu-icon"
          style={{ backgroundColor: "#ff4838" }}
          for="menu-btn"
        >
          <span className="navicon"></span>
        </label>
        
        <ul className="menu">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            {" "}
            <span style={{ color: "white" }}>
              <Link to="/Packages">Packages</Link>
            </span>
            {/* <ul className="sub-menu mt-2">
                                    <li><Link to="/InternationalPackages">Umrah Package</Link></li>
                                    <li><Link to="/InternationalPackages">Internation Package</Link></li>
                                    <li><Link to="/DomesticPackages">Domestic Package</Link></li>
                                    </ul> */}
          </li>

          <li>
            <Link to="/About-Us">About Us</Link>
          </li>
          <li>
            <Link to="/Contact-Us">Contact Us</Link>
          </li>
          
        
        </ul>
      </header>
      <div id="login" class="modal fade" role="dialog">
  <div class="modal-dialog">
    
    <div class="modal-content">
      <div class="modal-body">
        <button data-dismiss="modal" class="close">&times;</button>
        <h4>Login</h4>
        <form>
          <input type="text" name="username" class="username form-control" placeholder="Username"/>
          <input type="password" name="password" class="password form-control" placeholder="password"/>
          <input class="btn login" type="submit" value="Login" />
        </form>
      </div>
    </div>
  </div>  
</div>
    </>
      <Outlet />
    </>
  );
}
