import Footer from "../Footer";
import Header from "../Header";
import MainComponent from "../MainComponent";

export default function LayoutMainPage(){
  return(

  <>
      <Header />
    <MainComponent />
    <Footer />
  </>
  )
}