import LayoutMainPage from './Components/Layout/LayoutMainPage';
import '../src/Assets/css/select2.min.css';
import '../src/Assets/css/jquery.fancybox.min.css';
import '../src/Assets/css/boxicons.min.css';
import '../src/Assets/css/swiper-bundle.min.css';
import '../src/Assets/css/bootstrap.min.css';
import '../src/Assets/css/animate.min.css';
import '../src/Assets/css/responsive.css';
 import '../src/Assets/css/style.css';
 import { RouterProvider,createBrowserRouter } from 'react-router-dom';
import LayoutContact from './Components/Layout/LayoutContact';
import LayoutAbout from './Components/Layout/LayoutAbout';
import LayoutPackages from './Components/Layout/LayoutPackages';
 const MainRouting=createBrowserRouter([
  {path:'/',Component:LayoutMainPage},
  {path:'/Contact-Us',Component:LayoutContact},
  {path:'/About-Us',Component:LayoutAbout},
  {path:'/Packages',Component:LayoutPackages},
 ]);

function App() {
  
  return (
    <>
      <RouterProvider router={MainRouting}/>
    </>
  );
}

export default App;
