import { Link } from "react-router-dom";
import logo from '../Assets/images/JIT-Main-Logo.png';
import loader from '../Assets/images/Loader/loader3.gif'
import { useEffect, useState } from "react";
export default function Contact() {
let[isShowLoader,setisShowLoader]=useState(1);
let[isShowSnackbarToast,setisShowSnackbarToast]=useState(0);
let[name,setName]=useState('');
let[mobileNo,setMobileNo]=useState('');
let[email,setEmail]=useState('');
let[txtMessage,setTxtMessage]=useState('');

const handlerChange=(e)=>{
  const name=e.target.name;
  const value=e.target.value;
    if(name === "user_Name"){
      setName(value)
    }
    else if(name === "user_Mobile_No"){
      setMobileNo(value)
    } 
    else if(name === "user_Email"){
      setEmail(value)
    }
    else if(name === "user_Message"){
      setTxtMessage(value)
    }
}
const handlerSendGetInTouchReq=()=>{
 
  
  let config={
    Host: "smtp.elasticemail.com",
    Username: "ticket@jamaltourandtravels.com",
    Password: "DF89ABACE78F70E732F5513C5EC2DA684820",
    To: 'ticket@jamaltourandtravels.com',
    From: 'unborntech.dev@gmail.com',
    Subject: "Get in touch",
     Body: '<b>Alert - You Have A New Message</b><br>' +'Name :' + ' ' + '<b>' + name + '</b>' + '<br>' + 'Mobile No:' + ' ' + '<b>' + mobileNo + '</b>'+'<br/>' +'Email:'+'<b>' + email + '</b>' + '<br>' + 'Message :' + ' ' + '<b>' + txtMessage + '</b>' 
  }
  if(window.Email){
    window.Email.send(config);
    setisShowSnackbarToast(1)
    setTimeout(()=>{
      setisShowSnackbarToast(0);
      setName('');
      setMobileNo('');
      setEmail('');
      setTxtMessage('');
    },3000)
  }
}

useEffect(()=>{
setTimeout(()=>{
    setisShowLoader(0)
},2000)
},[])
  return (
    <>
         {isShowLoader === 1 ?
        <div class="preloader">
            <img src={loader} alt='Loader' style={{height:'10rem'}}/>
        </div>
    :''}  

      <div class="breadcrumb breadcrumb-style-one">
        <div class="container">
          <div class="col-lg-12 text-center">
            <h2 class="breadcrumb-title">Contact Us</h2>
            <ul class="d-flex justify-content-center breadcrumb-items">
              <li class="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li class="breadcrumb-item active">Contact Us</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="contact-wrapper pt-100">
        <div class="container">
          <div class="row align-items-center justify-content-lg-center gy-5">
            <div class="col-lg-6 col-sm-12">
              <div class="contatc-intro-figure">
                <img src={logo} alt='' className="img-fluid"/>
              </div>
            </div>
            <div class="col-lg-5 col-sm-12">
              <div class="contact-info">
                <h3>Contact Info.</h3>
                <ul>
                  <li>
                    <h6>Let’s Talk</h6>
                    <Link to="tel:05224053511">
                      <i class="fa fa-phone-square" aria-hidden="true"></i>
                      &nbsp;0522-4053511
                    </Link>
                    <Link to="tel:9936387710">
                      <i class="fa fa-phone-square" aria-hidden="true"></i>
                      &nbsp;+91 9936387710
                    </Link>
                  </li>
                  <li>
                    <h6>Loacation.</h6>
                    <Link to="https://maps.app.goo.gl/PmksqEnP2S3VqjtC9">
                    Shop No 03 Upper Ground Floor Medwell Hospital 37 <br/>Cantt Road Near Burlington Crossing  Lucknow: 226001
                    </Link>
                  </li>
                 
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="container mt-120">
            <div class="contact-form-wrap">
              <h4>Get in touch</h4>
              {/* <p>
                Your email address will not be published. Required fields are
                marked *
              </p> */}
              <div class="row">
                <div class="col-lg-4">
                  <div class="custom-input-group">
                    <label for="name">Name</label>
                    <input type="text" placeholder="Your name" id="name" name="user_Name" value={name} onChange={handlerChange} />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="custom-input-group">
                    <label for="email">Mobile No</label>
                    <input type="text" placeholder="Your Mobile No" id="email" name="user_Mobile_No" value={mobileNo} onChange={handlerChange}/>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="custom-input-group">
                    <label for="email">Email</label>
                    <input type="text" placeholder="Your Email" id="email" name="user_Email" value={email} onChange={handlerChange}/>
                  </div>
                </div>
              </div>
              <div class="custom-input-group">
                <textarea
                  cols="20"
                  rows="7"
                  placeholder="Your message"
                  name="user_Message" 
                  value={txtMessage}
                  onChange={handlerChange}
                  
                ></textarea>
              </div>
              <div class="custom-input-group">
                <div class="submite-btn">
                  <button type="submit" onClick={handlerSendGetInTouchReq}>Send Message</button>
                </div>
              </div>
            </div>
        </div>
        <div class="container-fluid p-0">
          <div class="contact-map mt-120">
            <div class="mapouter">
              <div class="gmap_canvas">
                <iframe
                  id="gmap_canvas"
                  src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d113918.38827308099!2d80.85460352550325!3d26.841554227937603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x399bfdcb127e0851%3A0xff6d3024eaa915d3!2sJamal%20Tour%20%26%20Travels%2C%20Medwell%20Hospital%2C%20Lucknow%2C%20Uttar%20Pradesh%20226001!3m2!1d26.8415781!2d80.937005!5e0!3m2!1sen!2sin!4v1697565038970!5m2!1sen!2sin"
                ></iframe>
                <Link to="https://123movies-to.org/"></Link>
                <br />
              </div>
            </div>
          </div>
        </div>
        
      </div>
      {isShowSnackbarToast === 1 ?
      <div className="show" id="snackbar">Send Successfully..</div>
      
      :''}
    </>
  );
}
