import About from "../About";
import Footer from "../Footer";
import Header from "../Header";

export default function LayoutAbout(){
    return(
        <>
            <Header />
            <About />
            <Footer />
        </>
    )
}