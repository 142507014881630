import Contact from "../Contact";
import Footer from "../Footer";
import Header from "../Header";

export default function LayoutContact(){
    return(
        <>
            <Header />
        <Contact />
        <Footer />
        </>
    )
}