import Footer from "../Footer";
import Header from "../Header";
import Packages from "../Packages";

export default function LayoutPackages(){
    return(
        <>
        <Header />
        <Packages />
        <Footer />
        </>
    )
}