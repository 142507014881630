import { Link } from "react-router-dom";
import JTTLogo from '../Assets/images/JIT-Main-Logo.png'
export default function Footer(){
    return(
        <div className="footer-area">
        <div className="footer-main-wrapper">
            <div className="footer-vactor">
                {/* <img src={JTTLogo} alt='' /> */}
            </div>
            <div className="container">
                <div className="row justify-content-center g-4">
                    <div className="col-lg-4">
                        <div className="footer-about text-lg-start text-center">
                            <p>we're more than just a travel agency we're your trusted partner in making your travel dreams a reality. Our mission is to create unforgettable, personalized experiences that enrich your life and broaden your horizons.</p>
                            <div className="footer-social-wrap">
                                <h5>Contact Us On:</h5>
                                <ul className="footer-social-links justify-content-lg-start justify-content-center">
                                    <li><Link to="mailto:ticket@jamaltourandtravels.com"><i class="fa fa-envelope" aria-hidden="true"></i></Link></li>
                                    <li><Link to="tel:05224053511"><i className="bx bx-phone"></i></Link></li>
                                    <li><Link to="https://wa.me/9936387710" target="_blank"><i className="bx bxl-whatsapp"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Quick Link</h4>
                            <ul className="footer-links">
                                <li><Link to="/About-Us">About Us</Link></li>
                                <li><Link to="/Contact-Us">Contact Us</Link></li>
                                <li><Link to="/Packages">Tour Package</Link></li>
                                <li><Link to="tel:9936387710">Umrah</Link></li>
                                <li><Link to="tel:9936387710">Flight Ticket</Link></li>
                                <li><Link to="tel:9936387710">Booking Process</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Tour Type</h4>
                            <ul className="footer-links">
                                <li><Link to="tel:9936387710">Wild & Adventure Tours</Link></li>
                                <li><Link to="tel:9936387710">Group Tour</Link></li>
                                <li><Link to="tel:9936387710">Seasonal Tours</Link></li>
                                <li><Link to="tel:9936387710">Relaxation Tours</Link></li>
                                <li><Link to="tel:9936387710">Family Friendly Tours</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4">
                        <div className="footer-widget"> 
                            <h4 className="footer-widget-title">Umrah Package</h4>
                            <ul className="footer-links">
                                <li><Link to="tel:9936387710">17 Days Umrah </Link></li>
                                <li><Link to="tel:9936387710">21 Days Umrah</Link></li>
                                <li><Link to="tel:9936387710">12 Rabi-ul-Awwal Umrah</Link></li>
                                <li><Link to="tel:9936387710">Ramdan Umrah</Link></li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
                <div className="footer-contact-wrapper ">
                    {/* <h5>Contact Us:</h5> */}
                    <ul className="footer-contact-list">
                         {/* <li><i className="bi bi-telephone-x"></i> <a href="tel:+17632275032">+1 763-227-5032</a></li> */}
                        
                        <li className=""><i className="bi bi-geo-alt"></i> <Link to="https://maps.app.goo.gl/PmksqEnP2S3VqjtC9" target="_blank">Shop No 03 Upper Ground Floor Medwell Hospital 37 Cantt Road Near Burlington Crossing  Lucknow: 226001</Link></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="footer-bottom">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-4 col-md-6 order-lg-1 order-3 ">
                        <div className="copyright-link text-lg-start text-center">
                            <p>Copyright 2022 JTT | Design By <Link to="#"> Unborn Tech</Link></p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    )
}