import { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import loader from "../Assets/images/Loader/loader3.gif";
import officeImg1 from "../Assets/images/Office/office-img-1.jpg";
import officeImg2 from "../Assets/images/Office/office-img-2.jpg";
import officeImg3 from "../Assets/images/Office/office-img-3.jpg";
import userImg from "../Assets/images/Office/userImg.png";
import Team from "./Team";
export default function About() {
  let [isShowLoader, setisShowLoader] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      setisShowLoader(0);
    }, 2000);
  }, []);
  return (
    <>
      {isShowLoader === 1 ? (
        <div class="preloader">
          <img src={loader} alt="Loader" style={{ height: "10rem" }} />
        </div>
      ) : (
        ""
      )}
      <div class="breadcrumb breadcrumb-style-one">
        <div class="container">
          <div class="col-lg-12 text-center">
            <h2 class="breadcrumb-title">About Us</h2>
            <ul class="d-flex justify-content-center breadcrumb-items">
              <li class="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              <li class="breadcrumb-item active">About Us</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="about-main-wrappper pt-100">
        <div class="container">
          <div class="about-tab-wrapper">
            <div class="row justify-content-center align-items-center">
              <div class="col-lg-6">
                <div class="about-tab-image-grid text-center">
                  <div class="about-video d-inline-block">
                    <img src={officeImg3} alt="" style={{ height: "20rem" }} />
                    {/* <div class="video-overlay">
<a data-fancybox href="https://www.youtube.com/watch?v=_sI_Ps7JSEk" class="play-icon video-popup">
<i class="bi bi-play-fill"></i>
</a>
</div> */}
                  </div>
                  <div class="row float-images g-4">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                      <div class="about-image">
                        <img src={officeImg1} alt="" />
                        {/* <img src="assets/images/about/about-g1.png" alt /> */}
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                      <div class="about-image">
                        <img src={officeImg2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mt-5 mt-lg-0">
                <div class="about-tab-wrap">
                  <h2 class="about-wrap-title">
                    About Our Company And <span>What We Are</span> Offer.
                  </h2>
                  <div class="about-tab-switcher">
                    <ul
                      class="nav nav-pills mb-3 justify-content-md-between justify-content-center"
                      id="about-tab-pills"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <div
                          class="nav-link active"
                          id="pills-about1"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills1"
                          role="tab"
                          aria-controls="about-pills1"
                          aria-selected="true"
                        >
                          <h3>100+</h3>
                          <h6>Destinations</h6>
                        </div>
                      </li>
                      <li class="nav-item" role="presentation">
                        <div
                          class="nav-link"
                          id="pills-about2"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills2"
                          role="tab"
                          aria-controls="about-pills2"
                          aria-selected="false"
                        >
                          <h3>10+</h3>
                          <h6>Products</h6>
                        </div>
                      </li>
                      <li class="nav-item" role="presentation">
                        <div
                          class="nav-link"
                          id="pills-about3"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills3"
                          role="tab"
                          aria-controls="about-pills3"
                          aria-selected="false"
                        >
                          <h3>10k+</h3>
                          <h6>Happy Customer</h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    class="tab-content about-tab-content"
                    id="pills-tabContent"
                  >
                    <div
                      class="tab-pane fade show active"
                      id="about-pills1"
                      role="tabpanel"
                      aria-labelledby="pills-about1"
                    >
                      <p>
                        we're more than just a travel agency we're your trusted
                        partner in making your travel dreams a reality. Our
                        mission is to create unforgettable, personalized
                        experiences that enrich your life and broaden your
                        horizons.
                      </p>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="about-pills2"
                      role="tabpanel"
                      aria-labelledby="pills-about2"
                    >
                      <p>
                        {" "}
                        Duis rutrum nisl urna. Maecenas vel libero faucibus nisi
                        venenatis hendrerit a id lectus. Suspendissendt blandit
                        interdum. Sed pellentesque at nunc eget consente
                        consectetur.Duis rutrum nisl urna. Maecenas vel libero
                        faucibus nisi venenatis hendrerit a id lectus.
                        Suspendissendt blandit interdum. Sed pellentesque at
                        nunc consectetur.Duis rutrum nisl urna. Maecenas vel
                        libero faucibus nisi venenatis hendrerit.
                      </p>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="about-pills3"
                      role="tabpanel"
                      aria-labelledby="pills-about3"
                    >
                      <p>
                        Duis rutrum nisl urna. Maecenas vel libero faucibus nisi
                        venenatis hendrerit a id lectus. Suspendissendt blandit
                        interdum. Sed pellentesque at nunc eget consente
                        consectetur.Duis rutrum nisl urna. Maecenas vel libero
                        faucibus nisi venenatis hendrerit a id lectus.
                        Suspendissendt blandit interdum. Sed pellentesque at
                        nunc consectetur.Duis rutrum nisl urna. Maecenas vel
                        libero faucibus nisi venenatis hendrerit a id lectus.
                        Suspendissendt blandit interdum. Sed pellentesque at
                        nunc consectetur.Duis rutrum nisl urna. Maecenas vel
                        libero faucibus nisi venenatis hendrerit a id lectus.
                        Suspendissendt blandit interdum. Sed pellentesque at
                        nunc consectetur.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-100 align-items-center">
            <div class="col-lg-6">
              <div class="achievement-counter-wrap">
                <h2 class="about-wrap-title">
                Why Choose 
                  &nbsp;<span>Us?</span>
                </h2>
                <div class="achievement-counter-cards">
                  <div class="achievement-counter-card flex-sm-row flex-column text-sm-start text-center ">
                   
                    <p>
                    Choose us because we don't just sell trips; we create experiences, turning ordinary journeys into extraordinary memories. With us, you're not a tourist; you're an explorer, and the world is your canvas. Let your wanderlust guide you, and let us be your compass.
                    </p>
                  </div>
                  <div class="achievement-counter-card flex-sm-row flex-column text-sm-start text-center ">
                   
                    <p>
                    Excellence is our tradition. We're not just in the business of travel; we're in the business of providing the best. Choose us for an unparalleled journey.
                    </p>
                  </div>
                  <div class="achievement-counter-card flex-sm-row flex-column text-sm-start text-center">
                    <div class="counter-box mb-3">
                      <h2>24x7</h2>
                      <h6> Exceptional Support:</h6>
                    </div>
                    <p>
                    Our dedicated team is available around the clock, ensuring you have peace of mind throughout your travels.
                    </p>
                  </div>
                  <div class="achievement-counter-card flex-sm-row flex-column text-sm-start text-center">
                    <div class="counter-box mb-3">
                      <h2>100+</h2>
                      <h6>Destination</h6>
                    </div>
                    <p>
                    From the enchanting to the exotic, we have 100+ destinations to ignite your wanderlust. Your dream adventure is just a choice away.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="col-lg-6">
              <div class="about-image-group mt-5 mt-lg-0">
                <img
                  src={}
                  alt
                  class="img-fluid"
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* <div class="testimonial-area testimonial-style-one mt-120">
        <div class="testimonial-shape-group"></div>
        <div class="container position-relative">
          <div class="row align-items-center">
            <div class="col-lg-8">
              <div class="section-head-alpha">
                <h2>What Our Client Say About Us</h2>
                <p>
                  Duis rutrum nisl urna. Maecenas vel libero faucibus nisi
                  venenatis hendrerit a id lectus. Suspendissendt blandit
                  interdum. Sed pellentesque at nunc eget consectetur.
                </p>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="slider-arrows text-center d-lg-flex d-none justify-content-end mb-3">
                <div
                  class="testi-prev custom-swiper-prev"
                  tabindex="0"
                  role="button"
                  aria-label="Previous slide"
                >
                  {" "}
                  <i class="bi bi-chevron-left"></i>{" "}
                </div>
                <div
                  class="testi-next custom-swiper-next"
                  tabindex="0"
                  role="button"
                  aria-label="Next slide"
                >
                  <i class="bi bi-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper testimonial-slider-one position-relative">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="testimonial-card testimonial-card-alpha">
                  <div class="testimonial-overlay-img">
                    <img src="assets/images/reviewer/reviewer-1.png" alt />
                  </div>
                  <div class="testimonial-card-top">
                    <div class="qoute-icon">
                      <i class="bx bxs-quote-left"></i>
                    </div>
                    <div class="testimonial-thumb">
                      <img src="assets/images/reviewer/r-sm1.png" alt />
                    </div>
                    <h3 class="testimonial-count">01</h3>
                  </div>
                  <div class="testimonial-body">
                    <p>
                      Duis rutrum nisl urna. Maecenas vel libero this faucibus
                      nisi venenatis hendrerit a id lectus.P Suspendissendt
                      molestie turpis nec laciniane vehicula volutpat purus.
                    </p>
                    <div class="testimonial-bottom">
                      <div class="reviewer-info">
                        <h4 class="reviewer-name">Shwan Pull</h4>
                        <h6>Traveller</h6>
                      </div>
                      <ul class="testimonial-rating">
                        <li>
                          <i class="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i class="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i class="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i class="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i class="bi bi-star-fill"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="testimonial-card testimonial-card-alpha">
                  <div class="testimonial-overlay-img">
                    <img src="assets/images/reviewer/reviewer-1.png" alt />
                  </div>
                  <div class="testimonial-card-top">
                    <div class="qoute-icon">
                      <i class="bx bxs-quote-left"></i>
                    </div>
                    <div class="testimonial-thumb">
                      <img src="assets/images/reviewer/r-sm2.png" alt />
                    </div>
                    <h3 class="testimonial-count">02</h3>
                  </div>
                  <div class="testimonial-body">
                    <p>
                      Duis rutrum nisl urna. Maecenas vel libero this faucibus
                      nisi venenatis hendrerit a id lectus.P Suspendissendt
                      molestie turpis nec laciniane vehicula volutpat purus.
                    </p>
                    <div class="testimonial-bottom">
                      <div class="reviewer-info">
                        <h4 class="reviewer-name">Adam Groff</h4>
                        <h6>Traveller</h6>
                      </div>
                      <ul class="testimonial-rating">
                        <li>
                          <i class="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i class="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i class="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i class="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i class="bi bi-star-fill"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="testimonial-card testimonial-card-alpha">
                  <div class="testimonial-overlay-img">
                    <img src="assets/images/reviewer/reviewer-1.png" alt />
                  </div>
                  <div class="testimonial-card-top">
                    <div class="qoute-icon">
                      <i class="bx bxs-quote-left"></i>
                    </div>
                    <div class="testimonial-thumb">
                      <img src="assets/images/reviewer/r-sm3.png" alt />
                    </div>
                    <h3 class="testimonial-count">03</h3>
                  </div>
                  <div class="testimonial-body">
                    <p>
                      Duis rutrum nisl urna. Maecenas vel libero this faucibus
                      nisi venenatis hendrerit a id lectus.P Suspendissendt
                      molestie turpis nec laciniane vehicula volutpat purus.
                    </p>
                    <div class="testimonial-bottom">
                      <div class="reviewer-info">
                        <h4 class="reviewer-name">Lilyan Danclark</h4>
                        <h6>Traveller</h6>
                      </div>
                      <ul class="testimonial-rating">
                        <li>
                          <i class="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i class="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i class="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i class="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i class="bi bi-star-fill"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Team />

      {/* <div class="blog-area blog-style-two pt-120">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <div class="section-head-gamma text-start">
                <h2>Latest Blog</h2>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="blog-btn text-lg-end">
                <a href="#" class="button-fill-round">
                  View All
                </a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="blog-card-gamma">
                <div class="blog-thumb">
                  <a href="blog-details.html">
                    <img src="assets/images/blog/blog-md-1.png" alt />
                  </a>
                  <div class="blog-lavel">
                    <a href="#">Guide</a>
                  </div>
                </div>
                <div class="blog-content">
                  <div class="blog-body-top">
                    <a href="#" class="blog-writer">
                      <i class="bi bi-person-circle"></i> By John Smith{" "}
                    </a>
                    <a href="#" class="blog-comments">
                      <i class="bi bi-calendar3"></i> Novembar 16, 2021
                    </a>
                  </div>
                  <h4 class="blog-title">
                    <a href="blog-details.html">
                      vitae tempor convallis, mi ligula an suscipit nunc, ornare
                      suscipit.
                    </a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="blog-card-gamma">
                <div class="blog-thumb">
                  <a href="blog-details.html">
                    <img src="assets/images/blog/blog-md-2.png" alt />
                  </a>
                  <div class="blog-lavel">
                    <a href="#">Travel</a>
                  </div>
                </div>
                <div class="blog-content">
                  <div class="blog-body-top">
                    <a href="#" class="blog-writer">
                      <i class="bi bi-person-circle"></i> By John Smith{" "}
                    </a>
                    <a href="#" class="blog-comments">
                      <i class="bi bi-calendar3"></i> Novembar 16, 2021
                    </a>
                  </div>
                  <h4 class="blog-title">
                    <a href="blog-details.html">
                      Mauris commodo massa vel diamnat feugiat sagittis Nunc
                      maxim.
                    </a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="blog-card-gamma">
                <div class="blog-thumb">
                  <a href="blog-details.html">
                    <img src="assets/images/blog/blog-md-3.png" alt />
                  </a>
                  <div class="blog-lavel">
                    <a href="#">Tour</a>
                  </div>
                </div>
                <div class="blog-content">
                  <div class="blog-body-top">
                    <a href="#" class="blog-writer">
                      <i class="bi bi-person-circle"></i> By John Smith{" "}
                    </a>
                    <a href="#" class="blog-comments">
                      <i class="bi bi-calendar3"></i> Novembar 16, 2021
                    </a>
                  </div>
                  <h4 class="blog-title">
                    <a href="blog-details.html">
                      Nulla urna leo, volutpat eu maurisa eget luctus malesuada
                      urna.
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

    
    </>
  );
}
