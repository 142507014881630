import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import bannerPlaneImage from '../Assets/images/banner/banner-plane.svg';
import thailandImg from '../Assets/images/package/thailand-banner.png';
import dubaiImg from '../Assets/images/package/dubai.jpeg';
import saudiImg from '../Assets/images/package/saudi-banner.jpg';
import kashmirImg from '../Assets/images/package/kashmir-banner.jpg';
import mussoorieImg from '../Assets/images/package/mussoorie-banner.jpg';
import goaImg from '../Assets/images/package/goa-banner.jpg';
import umrahBanner from '../Assets/images/services/umrahIconNew.png';
import holidayBanner from '../Assets/images/services/holiday.png';
import loader from '../Assets/images/Loader/loader3.gif'
import userImg from "../Assets/images/Office/userImg.png";
import counter_icon_customer from '../Assets/images/icon/counter-customer.svg';
import counter_icon_product from '../Assets/images/icon/counter-icon-product.svg';
import counter_icon_support from '../Assets/images/icon/support-icon.png';
import counter_icon_destination from '../Assets/images/icon/counter-icon-destination.svg';
import review from '../Assets/images/icon/quote-review.svg';
import reviewer_img from '../Assets/images/reviewer/review-user.png'
import Team from './Team';
export default function MainComponent(){
let[isShowLoader,setisShowLoader]=useState(1);
let[isShowSnackbarToast,setisShowSnackbarToast]=useState(0);
let[showSlideOne,setShowSlideOne]=useState(1);
let[showSlideTwo,setShowSlideTwo]=useState(0);
let[showSlideThree,setShowSlideThree]=useState(0);
let[joinus,setJoinus]=useState('');
const [count, setCount] = useState(0);
const handlerChange=(e)=>{
    const name=e.target.name;
    const value=e.target.value;
      
       if(name === "joinus"){
        setJoinus(value)
      }
     
  }
const handlerJoiUs =()=>{
    let config={
        Host: "smtp.elasticemail.com",
        Username: "ticket@jamaltourandtravels.com",
        Password: "DF89ABACE78F70E732F5513C5EC2DA684820",
        To: 'ticket@jamaltourandtravels.com',
        From: 'unborntech.dev@gmail.com',
        Subject: "New Joinee",
         Body: '<b>Alert - You Have A New Joinee</b><br>' +'Joinee Contact Details :' + ' ' + '<b>' + joinus + '</b>'  
      }
      if(window.Email){
        window.Email.send(config);
        setisShowSnackbarToast(1)
        setTimeout(()=>{
          setisShowSnackbarToast(0);
          setJoinus('')
        },3000)
      }
}
    let nextSlide =()=>{
        let slide1=showSlideOne;
        let slide2=showSlideTwo;
        let slide3=showSlideThree;
        if(slide1 === 1){
            setShowSlideThree(0);
            setShowSlideOne(0);
            setShowSlideTwo(1);
        }
        else if(slide2 === 1){
            setShowSlideOne(0);
            setShowSlideTwo(0);
            setShowSlideThree(1);
        }
        else if(slide3 === 1){
            setShowSlideThree(0);
            setShowSlideTwo(0);
            setShowSlideOne(1);
        }


        
    }
    const previouSlide =()=>{
        
        let slide2=showSlideTwo;
        let slide3=showSlideThree;
       
         if(slide2 === 1){
             setShowSlideTwo(0);
             setShowSlideThree(0);
             setShowSlideOne(1);
        }
        else if(slide3 === 1){
            setShowSlideThree(0);
            setShowSlideOne(0);
            setShowSlideTwo(1);
        }

    }
    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
      
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
    } 
    window.addEventListener("scroll", reveal);
useEffect(()=>{
setTimeout(()=>{
    setisShowLoader(0)
},1700)

},[])
    return(
        <>
            
    {isShowLoader === 1 ?
        <div class="preloader">
            <img src={loader} alt='Loader' style={{height:'10rem'}}/>
        </div>
    :''}  

{/* 
    <div className="row mt-2 ">
    <div className="col-lg-6"></div>
    <div className="col-lg-6">
    <div className="row">
    <div class="form-group col-lg-4 col-md-4 col-sm-4 mb-2 ">
    <input type="text" class="form-control" id="inputPassword2" placeholder="Username" style={{height:'35px',marginTop: '5px'}} />
  </div>
  <div class="form-group col-lg-4 col-md-4 col-sm-4 mb-2">
    <input type="password" class="form-control" id="inputPassword2" placeholder="Password" style={{height:'35px',marginTop: '5px'}} />
  </div>
  <div class="col-lg-2 col-md-4 col-sm-4 mb-2">
  <button type="submit" class="btn btn-success mb-2" style={{height: '35px',marginTop: '5px'}}><i class="fa fa-sign-in" aria-hidden="true"></i>&nbsp;Login</button>
  </div>
    </div>
    </div>
  
  </div> */}

    <div className="hero-area hero-style-three">
        <img src={bannerPlaneImage} className="img-fluid banner-plane" alt='' />
        <div className="hero-main-wrapper position-relative">
            {/* <div className="hero-social">
                <ul className="social-list d-flex justify-content-center align-items-center gap-4">
                    <li><a href="#">Facebook</a></li>
                    <li><a href="#">instagram</a></li>
                    <li><a href="#">Linked in</a></li>
                </ul>
            </div> */}
            <div className="swiper hero-slider-three ">
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <div className="slider-bg-1">
                            <div className="container">
                                <div className="row d-flex justify-content-center align-items-center">
                                    <div className="col-lg-8">
                                        <div className="hero3-content">
                                            <span className="title-top">Wellcome To <span>Jamal Tour & Travels</span></span>
                                            <h1>From A to Z, everywhere you want to go.</h1>
                                           
                                            <Link to='tel:9936387710' className="button-fill-primary banner3-btn">Book Your Travel</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="slider-arrows text-center d-lg-flex flex-column d-none gap-5">
                <div className="hero-prev3" tabindex="0" role="button" aria-label="Previous slide"> <i
                        className="bi bi-arrow-left"></i></div>
                <div className="hero-next3" tabindex="0" role="button" aria-label="Next slide"><i
                        className="bi bi-arrow-right"></i></div>
            </div> */}
        </div>
    </div>
    
    <div class="searchbar-section">
        <div class="container">
            <div class="multi-main-searchber">
                <div class="row g-4">
                    <div class="col-lg-10">
                        <div class="row gx-0 gy-4">
                            <div class="col-lg-3 col-md-6">
                                <div class="search-box-single destination-box">
                                    <div class="searchbox-icon">
                                        <i class="bi bi-geo-alt"></i>
                                    </div>
                                    <div class="searchbox-input">
                                        <label for="deatination_drop">Destination</label>
                                        <select data-placeholder="Where Are You Going?" id="deatination_drop">
                                            <option value>Where Are You Going?</option>
                                            <optgroup label="India">
                                                <option>
                                                    Solt Lake
                                                </option>
                                                <option>
                                                    Kolkata
                                                </option>
                                                <option>
                                                    Mohamia
                                                </option>
                                                <option>
                                                    Mumbai
                                                </option>
                                                <option>
                                                    New Delhi
                                                </option>
                                            </optgroup>
                                            <optgroup label=" Bangladash">
                                                <option>
                                                    Cox's Bazer
                                                </option>
                                                <option>
                                                    Rangamati
                                                </option>
                                                <option>
                                                    Banderban
                                                </option>
                                            </optgroup>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="search-box-single type-box">
                                    <div class="searchbox-icon">
                                        <i class="bi bi-text-paragraph"></i>
                                    </div>
                                    <div class="searchbox-input">
                                        <label for="activity-dropdown">Travel Type</label>
                                        <select class="defult-select-drowpown" data-placeholder="All Activity"
                                            id="activity-dropdown">
                                            <option value>All Activity</option>
                                            <option value="1">Type 1</option>
                                            <option value="2">Type 2</option>
                                            <option value="3">Type 3</option>
                                            <option value="4">Type 4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="search-box-single type-box">
                                    <div class="searchbox-icon">
                                        <i class="bi bi-person-plus"></i>
                                    </div>
                                    <div class="searchbox-input">
                                        <label for="person-dropdown">Person</label>
                                        <select class="defult-select-drowpown" id="person-dropdown">
                                            <option selected value="0">01</option>
                                            <option value="1">02</option>
                                            <option value="2">03</option>
                                            <option value="3">04</option>
                                            <option value="4">05</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="search-box-single date-box">
                                    <div class="searchbox-icon">
                                        <i class="bi bi-capslock"></i>
                                    </div>
                                    <div class="searchbox-input date-picker-input">
                                        <label for="datepicker">When</label>
                                        <input placeholder="Select your date" type="date" name="checkIn" 
                                              />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="main-form-submit">
                            <button type="submit">Search</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="package-area package-style-two pt-110 chain reveal fade-bottom">
        <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-lg-8 col-sm-10">
                    <div className="section-head-alpha text-center mx-auto">
                        <h2>Best Selling Tours</h2>
                        
                    </div>
                </div>
                {/* <div className="col-lg-4 col-sm-10">
                    <div className="package-btn text-lg-end">
                        <a href="package.html" className="button-fill-primary all-package-btn">View All Tour</a>
                    </div>
                </div> */}
            </div>
            <div className="row d-flex justify-content-center g-4">
                <div className="col-lg-4 col-md-6 col-sm-10  fadeffect">
                    <div className="package-card-beta">
                        <div className="package-thumb">
                            <a href="package-details.html"><img src={saudiImg} alt='saudi' /></a>
                            
                        </div>
                        <div className="package-card-body">
                            <h3 className="p-card-title"><a href="package-details.html">Saudi Arabia</a></h3>
                            <div className="p-card-bottom">
                                <div className="book-btn">
                                    <Link to="tel:9936387710">Book Now <i className="bx bxs-right-arrow-alt"></i></Link>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-10  fadeffect">
                    <div className="package-card-beta">
                        <div className="package-thumb">
                            <a href="package-details.html"><img src={thailandImg} alt='ThailandS' /></a>
                            
                        </div>
                        <div className="package-card-body">
                            <h3 className="p-card-title"><a href="package-details.html">Thailand</a></h3>
                            <div className="p-card-bottom">
                                <div className="book-btn">
                                <Link to="tel:9936387710">Book Now <i className="bx bxs-right-arrow-alt"></i></Link>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-10 fadeffect ">
                    <div className="package-card-beta">
                        <div className="package-thumb">
                            <a href="package-details.html"><img src={dubaiImg} alt='' /></a>
                            
                        </div>
                        <div className="package-card-body">
                            <h3 className="p-card-title"><a href="package-details.html">Dubai</a></h3>
                            <div className="p-card-bottom">
                                <div className="book-btn">
                                <Link to="tel:9936387710">Book Now <i className="bx bxs-right-arrow-alt"></i></Link>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-10 fadeffect ">
                    <div className="package-card-beta">
                        <div className="package-thumb">
                            <a href="package-details.html"><img src={mussoorieImg} alt='' /></a>
                           
                        </div>
                        <div className="package-card-body">
                            <h3 className="p-card-title"><a href="package-details.html">Mussoorie</a></h3>
                            <div className="p-card-bottom">
                                <div className="book-btn">
                                <Link to="tel:9936387710">Book Now <i className="bx bxs-right-arrow-alt"></i></Link>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-10 fadeffect">
                    <div className="package-card-beta">
                        <div className="package-thumb">
                            <a href="package-details.html"><img src={kashmirImg} alt='' /></a>
                            
                        </div>
                        <div className="package-card-body">
                            <h3 className="p-card-title"><a href="package-details.html">Kashmir</a></h3>
                            <div className="p-card-bottom">
                                <div className="book-btn">
                                <Link to="tel:9936387710">Book Now <i className="bx bxs-right-arrow-alt"></i></Link>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-10 fadeffect">
                    <div className="package-card-beta">
                        <div className="package-thumb">
                            <a href="package-details.html"><img src={goaImg} alt='' /></a>
                            
                        </div>
                        <div className="package-card-body">
                            <h3 className="p-card-title"><a href="package-details.html">Goa</a></h3>
                            <div className="p-card-bottom">
                                <div className="book-btn">
                                <Link to="tel:9936387710">Book Now <i className="bx bxs-right-arrow-alt"></i></Link>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="package-page-btn text-center mt-60">
                <a href="" className="button-fill-round">View All</a>
            </div> */}
        </div>
    </div>


    {/* <div className="destination-area destination-style-two pt-110 pb-110">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8 col-sm-10 ">
                    <div className="section-head-alpha text-center mx-auto">
                        <h2>Top Destination</h2>
                        <p>Duis rutrum nisl urna. Maecenas vel libero faucibus nisi venenatis hendrerit a id lectus.
                            Suspendissendt blandit interdum.</p>
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-center g-4">
                <div className="col-lg-6 col-md-12 col-sm-10 fadeffect">
                    <div className="destination-item">
                        <div className="destination-img">
                            <img src="assets/images/destination/top-ds1.png" alt />
                        </div>
                        <div className="destination-overlay">
                            <div className="content">
                                <a href="destination-details.html">
                                    <h5>Grand Switzerland</h5>
                                </a>
                                <a href="destination-details.html">
                                    <h6>45 Place</h6>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-10 fadeffect">
                    <div className="destination-item">
                        <div className="destination-img">
                            <img src="assets/images/destination/top-ds2.png" alt />
                        </div>
                        <div className="destination-overlay">
                            <div className="content">
                                <a href="destination-details.html">
                                    <h5>Paris</h5>
                                </a>
                                <a href="destination-details.html">
                                    <h6>35 Place</h6>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-10 fadeffect">
                    <div className="destination-item">
                        <div className="destination-img">
                            <img src="assets/images/destination/top-ds3.png" alt />
                        </div>
                        <div className="destination-overlay">
                            <div className="content">
                                <a href="destination-details.html">
                                    <h5>Augsburg</h5>
                                </a>
                                <a href="destination-details.html">
                                    <h6>15 Place</h6>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-10 fadeffect">
                    <div className="destination-item">
                        <div className="destination-img">
                            <img src="assets/images/destination/top-ds4.png" alt />
                        </div>
                        <div className="destination-overlay">
                            <div className="content">
                                <a href="destination-details.html">
                                    <h5>Paris</h5>
                                </a>
                                <a href="destination-details.html">
                                    <h6>34 Place</h6>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-10 fadeffect">
                    <div className="destination-item">
                        <div className="destination-img">
                            <img src="assets/images/destination/top-ds5.png" alt />
                        </div>
                        <div className="destination-overlay">
                            <div className="content">
                                <a href="destination-details.html">
                                    <h5>Wiesbaden</h5>
                                </a>
                                <a href="destination-details.html">
                                    <h6>10 Place</h6>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-10 fadeffect">
                    <div className="destination-item">
                        <div className="destination-img">
                            <img src="assets/images/destination/top-ds6.png" alt />
                        </div>
                        <div className="destination-overlay">
                            <div className="content">
                                <a href="destination-details.html">
                                    <h5>UK</h5>
                                </a>
                                <a href="destination-details.html">
                                    <h6>67 Place</h6>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}


    {/* <div className="upcoming-tour-area pt-110 pb-110 chain">
        <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-lg-5 d-flex justify-content-lg-start justify-content-center">
                    <div className="section-head-alpha text-lg-start text-center">
                        <h2 className="text-white">Upcoming Best Tours</h2>
                        <p className="text-white">Duis rutrum nisl urna maecenas vel libero faucibus nisi vene natis
                            hendrerit aid lectus suspendissendt.</p>
                    </div>
                </div>
                <div className="col-lg-7 d-flex justify-content-lg-end justify-content-center">
                    <div className="slider-arrows text-center d-lg-flex flex-row justify-content-center d-none gap-5">
                        <div className="testi-prev4" tabindex="0" role="button" aria-label="Previous slide"><i
                                className="bi bi-arrow-left"></i></div>
                        <div className="testi-next4" tabindex="0" role="button" aria-label="Next slide"><i
                                className="bi bi-arrow-right"></i></div>
                    </div>
                </div>
            </div>
            <div className="row align-items-center justify-content-center fadeffect">
                <div className="col-lg-12">
                    <div className="swiper upcoming-tour">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="package-card-beta">
                                    <div className="package-thumb">
                                        <a href="package-details.html"><img src="assets/images/package/best-s1.png"
                                                alt /></a>
                                        <p className="card-lavel">
                                            <i className="bi bi-clock"></i> <span>3 Day & 2 night</span>
                                        </p>
                                    </div>
                                    <div className="package-card-body">
                                        <h3 className="p-card-title"><a href="package-details.html">Etiam placerat dictum
                                                consequat an
                                                Pellentesque.</a></h3>
                                        <div className="p-card-bottom">
                                            <div className="book-btn">
                                                <a href="package-details.html">Book Now <i
                                                        className="bx bxs-right-arrow-alt"></i></a>
                                            </div>
                                            <div className="p-card-info">
                                                <span>From</span>
                                                <h6>$79.00 <span>Per Person</span></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="package-card-beta">
                                    <div className="package-thumb">
                                        <a href="package-details.html"><img src="assets/images/package/best-s2.png"
                                                alt /></a>
                                        <p className="card-lavel">
                                            <i className="bi bi-clock"></i> <span>5 Day & 6 night</span>
                                        </p>
                                    </div>
                                    <div className="package-card-body">
                                        <h3 className="p-card-title"><a href="package-details.html">varius condimentum
                                                consequat frin
                                                Aenean.</a></h3>
                                        <div className="p-card-bottom">
                                            <div className="book-btn">
                                                <a href="package-details.html">Book Now <i
                                                        className="bx bxs-right-arrow-alt"></i></a>
                                            </div>
                                            <div className="p-card-info">
                                                <span>From</span>
                                                <h6>$67.00 <span>Per Person</span></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="package-card-beta">
                                    <div className="package-thumb">
                                        <a href="package-details.html"><img src="assets/images/package/best-s3.png"
                                                alt /></a>
                                        <p className="card-lavel">
                                            <i className="bi bi-clock"></i> <span>4 Day & 3 night</span>
                                        </p>
                                    </div>
                                    <div className="package-card-body">
                                        <h3 className="p-card-title"><a href="package-details.html">Praesent sed elit mi. In
                                                risus
                                                nullaam ultricies sapien.</a></h3>
                                        <div className="p-card-bottom">
                                            <div className="book-btn">
                                                <a href="package-details.html">Book Now <i
                                                        className="bx bxs-right-arrow-alt"></i></a>
                                            </div>
                                            <div className="p-card-info">
                                                <span>From</span>
                                                <h6>$88.00 <span>Per Person</span></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="package-card-beta">
                                    <div className="package-thumb">
                                        <a href="package-details.html"><img src="assets/images/package/best-s4.png"
                                                alt /></a>
                                        <p className="card-lavel">
                                            <i className="bi bi-clock"></i> <span>5 Day & 6 night</span>
                                        </p>
                                    </div>
                                    <div className="package-card-body">
                                        <h3 className="p-card-title"><a href="package-details.html">Sed ultricies sapien
                                                arcu, sed cong
                                                feugiat sapien dignissim. </a></h3>
                                        <div className="p-card-bottom">
                                            <div className="book-btn">
                                                <a href="package-details.html">Book Now <i
                                                        className="bx bxs-right-arrow-alt"></i></a>
                                            </div>
                                            <div className="p-card-info">
                                                <span>From</span>
                                                <h6>$91.00 <span>Per Person</span></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="package-card-beta">
                                    <div className="package-thumb">
                                        <a href="package-details.html"><img src="assets/images/package/best-s5.png"
                                                alt /></a>
                                        <p className="card-lavel">
                                            <i className="bi bi-clock"></i> <span>4 Day & 3 night</span>
                                        </p>
                                    </div>
                                    <div className="package-card-body">
                                        <h3 className="p-card-title"><a href="package-details.html">Pellentesque habitant
                                                morbi malesua
                                                tristique senectus.</a></h3>
                                        <div className="p-card-bottom">
                                            <div className="book-btn">
                                                <a href="package-details.html">Book Now <i
                                                        className="bx bxs-right-arrow-alt"></i></a>
                                            </div>
                                            <div className="p-card-info">
                                                <span>From</span>
                                                <h6>$67.00 <span>Per Person</span></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row text-center d-flex justify-content-center">
                <div className="col-md-4">
                    <a href="destination.html" className="button-outlined-primary upcoming-btn mt-50">View All Offer</a>
                </div>
            </div>
        </div>
    </div> */}


    <div className="package-area offer-package-style-one pt-110 pb-110">
        <div className="container">
            
            <div className="row align-items-center">
               
             
            <section class="we-offer-area text-center bg-gray">
        <div class="container reveal fade-bottom">
            <div class="row">
                <div class="col-md-12">
                    <div class="site-heading text-center">
                        <h2>What we <span>Offer</span></h2>
                        <h4>Our Services</h4>
                    </div>
                </div>
            </div>
                <div class="row our-offer-items less-carousel ">
                <div class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                           <img src={umrahBanner} alt='Umrah' style={{height: "7rem"}}/>
                            <h4>Umrah Packages</h4>
                            
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                        <img src={holidayBanner} alt='Umrah' style={{height: "7rem"}}/>
                            <h4>Tour Packages</h4>
                           
                        </div>
                    </div>
                   
                    <div class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                        <i class="fa fa-plane"></i>
                            <h4>Flight Ticket</h4>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                        <i class="fa fa-h-square" aria-hidden="true"></i>
                            <h4>Hotels</h4>
                            
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                        <i class="fa fa-address-card-o" aria-hidden="true"></i>
                            <h4>Visa</h4>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                        <i class="fa fa-handshake-o" aria-hidden="true"></i>
                            <h4>Travel Consultant </h4>
                            
                        </div>
                    </div>
                </div>
        </div>
             </section>
            </div>
        </div>
    </div>


    <div className="testimonial-area testimonial-style-three pt-110 pb-110">
        <div className="container-fluid">
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-lg-5 col-sm-10">
                    <div className="section-head-alpha text-lg-start text-center">
                        <h2 className="text-white">Travelers Review</h2>
                        
                    </div>
                </div>
                
            </div>
           
            <div className="row align-items-center justify-content-center">
                <div className="col-lg-12">
                    <div className="swiper testimonial-slider-three">
                        <div className="swiper-wrapper">
                            {showSlideOne === 1 ? 
                                <div className="swiper-slide">
                                <div className="testimonial-card-beta mx-auto">
                                    <div className="testimonial-quote-icon">
                                        <img src={review} className="img-fluid" alt='' />
                                    </div>
                                    <div className="testimonial-card-body">
                                        <div className="testimonial-info">
                                            <div className="reviewr-info">
                                                <div className="single-thumb">
                                                    <img src={reviewer_img} alt='' />
                                                </div>
                                                <div className="reviwer-info-cotn">
                                                    <h5 className="reviewer-name">Sameer Khan</h5>
                                                    <ul className="reviewer-rating gap-2">
                                                        <li>
                                                            <i className="bi bi-star-fill"></i>
                                                        </li>
                                                        <li>
                                                            <i className="bi bi-star-fill"></i>
                                                        </li>
                                                        <li>
                                                            <i className="bi bi-star-fill"></i>
                                                        </li>
                                                        <li>
                                                            <i className="bi bi-star-fill"></i>
                                                        </li>
                                                        <li>
                                                            <i className="bi bi-star-fill"></i>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="review-texts">
                                            <p>
                                            Would recommend this Travel Agency. Hassle free and clear communication. Provide a great service and our holiday was value for money
                                            </p>
                                        </div>
                                        <div className="review-texts slideHover">
                                            <p>
                                            <i class="fa fa-arrow-left slidePrev" aria-hidden="true"  style={{color:'#ff4838',fontSize: '1.5rem'}} title='Previous' onClick={previouSlide}></i>
                                            &nbsp;&nbsp;&nbsp;
                                            <i class="fa fa-arrow-right slideNext" aria-hidden="true" style={{color:'#ff4838',fontSize: '1.5rem'}} title='Next' onClick={nextSlide}></i>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            : showSlideTwo === 1 ? 
                                <div className="swiper-slide">
                                    <div className="testimonial-card-beta mx-auto">
                                        <div className="testimonial-quote-icon">
                                            <img src={review} className="img-fluid" alt='' />
                                        </div>
                                        <div className="testimonial-card-body">
                                            <div className="testimonial-info">
                                                <div className="reviewr-info">
                                                    <div className="single-thumb">
                                                        <img src={reviewer_img} alt='' />
                                                    </div>
                                                    <div className="reviwer-info-cotn">
                                                        <h5 className="reviewer-name">Riya Yadav</h5>
                                                        <ul className="reviewer-rating gap-2">
                                                            <li>
                                                                <i className="bi bi-star-fill"></i>
                                                            </li>
                                                            <li>
                                                                <i className="bi bi-star-fill"></i>
                                                            </li>
                                                            <li>
                                                                <i className="bi bi-star-fill"></i>
                                                            </li>
                                                            <li>
                                                                <i className="bi bi-star-fill"></i>
                                                            </li>
                                                            <li>
                                                                <i className="bi bi-star-fill"></i>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="review-texts">
                                                <p>
                                                    Amazing Travel Agency Superb Service
                                                </p>
                                            </div>
                                            <div className="review-texts slideHover">
                                            <p>
                                            <i class="fa fa-arrow-left slidePrev" aria-hidden="true"  style={{color:'#ff4838',fontSize: '1.5rem'}} title='Previous' onClick={previouSlide}></i>
                                            &nbsp;&nbsp;&nbsp;
                                            <i class="fa fa-arrow-right slideNext" aria-hidden="true" style={{color:'#ff4838',fontSize: '1.5rem'}} title='Next' onClick={nextSlide}></i>

                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            : showSlideThree === 1 ? 
                                <div className="swiper-slide">
                                    <div className="testimonial-card-beta mx-auto">
                                        <div className="testimonial-quote-icon">
                                            <img src={review} className="img-fluid" alt='' />
                                        </div>
                                        <div className="testimonial-card-body">
                                            <div className="testimonial-info">
                                                <div className="reviewr-info">
                                                    <div className="single-thumb">
                                                        <img src={reviewer_img} alt='' />
                                                    </div>
                                                    <div className="reviwer-info-cotn">
                                                        <h5 className="reviewer-name">Fatima Sheikh</h5>
                                                        <ul className="reviewer-rating gap-2">
                                                            <li>
                                                                <i className="bi bi-star-fill"></i>
                                                            </li>
                                                            <li>
                                                                <i className="bi bi-star-fill"></i>
                                                            </li>
                                                            <li>
                                                                <i className="bi bi-star-fill"></i>
                                                            </li>
                                                            <li>
                                                                <i className="bi bi-star-fill"></i>
                                                            </li>
                                                            <li>
                                                                <i className="bi bi-star-fill"></i>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="review-texts">
                                                <p>
                                                Great customer service , fast and prompt reply me and my parents Umrah visa sorted within one week . 100 percent recommend
                                                </p>
                                            </div>
                                            <div className="review-texts slideHover">
                                            <p>
                                            <i class="fa fa-arrow-left slidePrev" aria-hidden="true"  style={{color:'#ff4838',fontSize: '1.5rem'}} title='Previous' onClick={previouSlide}></i>
                                            &nbsp;&nbsp;&nbsp;
                                            <i class="fa fa-arrow-right slideNext" aria-hidden="true" style={{color:'#ff4838',fontSize: '1.5rem'}} title='Next' onClick={nextSlide}></i>

                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            :''}
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    
     <Team />

   

    <div class="newslatter-wrapper mt-120">
<div class="container">
<div class="row align-items-center">
<div class="col-lg-6">
<div class="newslatter-side text-center text-lg-start mx-auto mx-lg-0">
<h2>Join Us for<span> More Update </span></h2>

<div class="newslatter-form-input">
<input type="text" name="joinus" id="newslatter-input" placeholder="Enter Email / Mobile Number Here..."  value={joinus}  onChange={handlerChange}/>
<button type="submit" class="newslatter-submit" onClick={handlerJoiUs}>JOIN</button>
</div>

</div>
</div>
<div class="col-lg-6 ">
<div class="achievement-counter-side">
<div class="row g-4">
<div class="col-lg-6  col-md-6">
<div class="achievement-box-style-one">
<div class="achievement-icon">
</div>
<div class="achievement-box-content">
<img src={counter_icon_customer} alt='' />
<h2>10K+</h2>
<h4>Happy Customer</h4>
</div>
</div>
</div>
<div class="col-lg-6  col-md-6">
<div class="achievement-box-style-one">
<div class="achievement-icon">
</div>
<div class="achievement-box-content">
<img src={counter_icon_destination} alt='' style={{height: '2rem'}}/>
<h2>100+</h2>
<h4>Destinations</h4>
</div>
</div>
</div>
<div class="col-lg-6  col-md-6">
<div class="achievement-box-style-one">
<div class="achievement-icon">
</div>
<div class="achievement-box-content">
<img src={counter_icon_support} alt='' style={{height: '3.5rem'}}/>
<h2>24x7</h2>
<h4>Exceptional Support</h4>
</div>
</div>
</div>
<div class="col-lg-6  col-md-6">
<div class="achievement-box-style-one">
<div class="achievement-icon">
</div>
<div class="achievement-box-content">
<img src={counter_icon_product} alt='' />
<h2>10+</h2>
<h4>Products</h4>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
   
    {isShowSnackbarToast === 1 ?
      <div className="show" id="snackbar">Send Successfully..</div>
      
      :''}

    
        </>
    )
}