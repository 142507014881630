import thailandImg from "../Assets/images/package/thailand-banner.png";
import dubaiImg from "../Assets/images/package/dubai.jpeg";
import saudiImg from "../Assets/images/package/saudi-banner.jpg";
import kashmirImg from "../Assets/images/package/kashmir-banner.jpg";
import mussoorieImg from "../Assets/images/package/mussoorie-banner.jpg";
import goaImg from "../Assets/images/package/goa-banner.jpg";
import umrahBanner from "../Assets/images/services/umrahIconNew.png";
import holidayBanner from "../Assets/images/services/holiday.png";
import loader from "../Assets/images/Loader/loader3.gif";
import kerala from '../Assets/images/destination/kerala.jpg';
import shimla from '../Assets/images/destination/shimla.jpg';
import darjeeling from '../Assets/images/destination/Darjeeling.jpg';
import mumbai from '../Assets/images/destination/Mumbai.jpg';
import manali from '../Assets/images/destination/manali.jpg';
import nainital from '../Assets/images/destination/Nainital.jpg';
import qatar from '../Assets/images/destination/qatar.jpg';
import maldives from '../Assets/images/destination/maldives.jpg';
import turkey from '../Assets/images/destination/turkey.jpg';
import Canada from '../Assets/images/destination/Canada.jpg';
import Muscat from '../Assets/images/destination/Muscat.jpg';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
export default function Packages() {
  let[isShowLoader,setisShowLoader]=useState(1);
  useEffect(()=>{
    setTimeout(()=>{
        setisShowLoader(0)
    },2000)
    },[])
  return (
    <>
     {isShowLoader === 1 ?
        <div class="preloader">
            <img src={loader} alt='Loader' style={{height:'10rem'}}/>
        </div>
    :''}  

      <div class="breadcrumb breadcrumb-style-one">
        <div class="container">
          <div class="col-lg-12 text-center">
            <h2 class="breadcrumb-title">Destinations</h2>
            <ul class="d-flex justify-content-center breadcrumb-items">
              <li class="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li class="breadcrumb-item active">Destination</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="destination-wrapper pt-110">
        <div class="container">
        <div className="col-lg-12 col-sm-12">
                    <div className="section-head-alpha text-center mx-auto">
                        <h2>Domestic Destination</h2>
                        
                    </div>
                </div>
          <div class="row g-3">
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="destination-card-style-two">
                <div class="d-card-thumb">
                  <img src={shimla} alt="" />
                </div>
                <div class="d-card-content">
                  <h4 class="destination-title">
                    <a href="destination-details.html">Shimla</a>
                  </h4>
                  <div class="place-count">
                  <Link to='tel:9936387710'>Book Now </Link> 
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="destination-card-style-two">
                <div class="d-card-thumb">
                  <img src={manali} alt="" />
                </div>
                <div class="d-card-content">
                  <h4 class="destination-title">
                    <a href="destination-details.html">Manali</a>
                  </h4>
                  <div class="place-count">
                  <Link to='tel:9936387710'>Book Now </Link> 
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="destination-card-style-two">
                <div class="d-card-thumb">
                  <img src={darjeeling} alt="" />
                </div>
                <div class="d-card-content">
                  <h4 class="destination-title">
                    <a href="destination-details.html">Darjeeling</a>
                  </h4>
                  <div class="place-count">
                  <Link to='tel:9936387710'>Book Now </Link> 
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="destination-card-style-two">
                <div class="d-card-thumb">
                  <img src={nainital} alt="" />
                </div>
                <div class="d-card-content">
                  <h4 class="destination-title">
                    <a href="destination-details.html">Nainital</a>
                  </h4>
                  <div class="place-count">
                  <Link to='tel:9936387710'>Book Now </Link> 
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="destination-card-style-two">
                <div class="d-card-thumb">
                  <img src={kashmirImg} alt="" />
                </div>
                <div class="d-card-content">
                  <h4 class="destination-title">
                    <a href="destination-details.html">Kashmir</a>
                  </h4>
                  <div class="place-count">
                    <Link to='tel:9936387710'>Book Now </Link> 
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="destination-card-style-two">
                <div class="d-card-thumb">
                  <img src={mussoorieImg} alt="" />
                </div>
                <div class="d-card-content">
                  <h4 class="destination-title">
                    <a href="destination-details.html">Mussoorie</a>
                  </h4>
                  <div class="place-count">
                  <Link to='tel:9936387710'>Book Now </Link> 
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="destination-card-style-two">
                <div class="d-card-thumb">
                  <img src={goaImg} alt='' />
                </div>
                <div class="d-card-content">
                  <h4 class="destination-title">
                    <a href="destination-details.html">Goa</a>
                  </h4>
                  <div class="place-count">
                  <Link to='tel:9936387710'>Book Now </Link> 
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="destination-card-style-two">
                <div class="d-card-thumb">
                  <img src={kerala} alt="" />
                </div>
                <div class="d-card-content">
                  <h4 class="destination-title">
                    <a href="destination-details.html">Kerala</a>
                  </h4>
                  <div class="place-count">
                  <Link to='tel:9936387710'>Book Now </Link> 
                  </div>
                </div>
              </div>
            </div>

            
          </div>
         
        </div>
      </div>
      <div class="destination-wrapper pt-110">
        <div class="container">
        <div className="col-lg-12 col-sm-12">
                    <div className="section-head-alpha text-center mx-auto">
                        <h2>International Destination</h2>
                        
                    </div>
                </div>
          <div class="row g-3">
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="destination-card-style-two">
                <div class="d-card-thumb">
                  <img src={saudiImg} alt="" />
                </div>
                <div class="d-card-content">
                  <h4 class="destination-title">
                    <a href="destination-details.html">Saudi</a>
                  </h4>
                  <div class="place-count">
                  <Link to='tel:9936387710'>Book Now </Link> 
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="destination-card-style-two">
                <div class="d-card-thumb">
                  <img src={dubaiImg} alt="" />
                </div>
                <div class="d-card-content">
                  <h4 class="destination-title">
                    <a href="destination-details.html">Dubai</a>
                  </h4>
                  <div class="place-count">
                    <Link to='tel:9936387710'>Book Now </Link> 
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="destination-card-style-two">
                <div class="d-card-thumb">
                  <img src={qatar} alt="" />
                </div>
                <div class="d-card-content">
                  <h4 class="destination-title">
                    <a href="destination-details.html">Qatar</a>
                  </h4>
                  <div class="place-count">
                  <Link to='tel:9936387710'>Book Now </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="destination-card-style-two">
                <div class="d-card-thumb">
                  <img src={Muscat} alt="" />
                </div>
                <div class="d-card-content">
                  <h4 class="destination-title">
                    <a href="destination-details.html">Muscat</a>
                  </h4>
                  <div class="place-count">
                  <Link to='tel:9936387710'>Book Now </Link>
                  </div>
                </div>
              </div>
            </div>
           
           
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="destination-card-style-two">
                <div class="d-card-thumb">
                  <img src={thailandImg} alt="" />
                </div>
                <div class="d-card-content">
                  <h4 class="destination-title">
                    <a href="destination-details.html">Thailand</a>
                  </h4>
                  <div class="place-count">
                  <Link to='tel:9936387710'>Book Now </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="destination-card-style-two">
                <div class="d-card-thumb">
                  <img src={Canada} alt="" />
                </div>
                <div class="d-card-content">
                  <h4 class="destination-title">
                    <a href="destination-details.html">Canada</a>
                  </h4>
                  <div class="place-count">
                  <Link to='tel:9936387710'>Book Now </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="destination-card-style-two">
                <div class="d-card-thumb">
                  <img src={turkey} alt="" />
                </div>
                <div class="d-card-content">
                  <h4 class="destination-title">
                    <a href="destination-details.html">Turkey</a>
                  </h4>
                  <div class="place-count">
                  <Link to='tel:9936387710'>Book Now </Link>
                  </div>
                </div>
              </div>
            </div>
            
           
            <div class="col-lg-3 col-md-4 col-sm-6">
              <div class="destination-card-style-two">
                <div class="d-card-thumb">
                  <img src={maldives} alt="" />
                </div>
                <div class="d-card-content">
                  <h4 class="destination-title">
                    <a href="destination-details.html">Maldives</a>
                  </h4>
                  <div class="place-count">
                  <Link to='tel:9936387710'>Book Now </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
}
